<template>
  <v-container fluid class="p-0">
    <v-row justify="start">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card outlined class="mb-4">
          <v-card-title
            class="text-h6 font-weight-bold clickable d-flex justify-space-between align-center"
            @click="toggleHolidaySettings"
          >
            <span>Nastavenia prázdnin</span>
            <v-icon>
              {{ showHolidaySettings ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-card-title>
          <v-expand-transition>
            <v-card-text v-show="showHolidaySettings">
              <v-select
                v-model="selectedSchool"
                :items="schoolOptions"
                item-title="title"
                item-value="value"
                label="Vyberte školu"
                variant="solo"
                class="mb-4"
                :loading="loadingSchools"
                :disabled="loadingSchools"
                :rules="[(v) => !!v || 'Výber školy je povinný']"
                @update:model-value="onSchoolChange"
              ></v-select>

              <!-- eslint-disable-next-line -->
              <div v-for="(holiday, index) in holidays"
                :key="holiday.id"
                class="holiday-form mb-4"
              >
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="holiday.startDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ props }">
                        <v-text-field
                          v-model="holiday.startDateFormatted"
                          label="Od"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          variant="underlined"
                          density="compact"
                          v-bind="props"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-card>
                        <v-date-picker
          v-model="holiday.startDate"
          @update:model-value="updateDate(holiday, 'startDate')"
          :landscape="true"
          class="custom-date-picker input-field"
          :allowed-dates="allowedDates"
          @update:model-month-year="updateCurrentMonth"
        ></v-date-picker>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="grey"
                            @click="holiday.startDateMenu = false"
                            >Zavrieť</v-btn
                          >
                          <v-btn
                            text
                            color="purple"
                            @click="resetDate(holiday, 'startDate')"
                            >Resetovať</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="holiday.endDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ props }">
                        <v-text-field
                          v-model="holiday.endDateFormatted"
                          label="Do"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          variant="underlined"
                          density="compact"
                          v-bind="props"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-card>
                        <v-date-picker
          v-model="holiday.endDate"
          @update:model-value="updateDate(holiday, 'endDate')"
          :landscape="true"
          class="custom-date-picker input-field"
          :allowed-dates="allowedDates"
          @update:model-month-year="updateCurrentMonth"
        ></v-date-picker>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="grey"
                            @click="holiday.endDateMenu = false"
                            >Zavrieť</v-btn
                          >
                          <v-btn
                            text
                            color="purple"
                            @click="resetDate(holiday, 'endDate')"
                            >Resetovať</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="11">
                    <v-text-field
                      v-model="holiday.description"
                      label="Popis"
                      variant="underlined"
                      density="compact"
                      class="small-field"
                      @input="updateDescription(holiday)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" class="d-flex align-center justify-end">
                    <v-btn icon @click="deleteHoliday(holiday.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <div
                v-for="(holiday, index) in holidaysToAdd"
                :key="index"
                class="holiday-form mb-4"
              >
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="holiday.startDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ props }">
                        <v-text-field
                          v-model="holiday.startDateFormatted"
                          label="Od"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          variant="underlined"
                          density="compact"
                          v-bind="props"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-card>
                        <v-date-picker
                          v-model="holiday.startDate"
                          @update:model-value="updateDate(holiday, 'startDate')"
                          :landscape="true"
                          class="custom-date-picker input-field"
                          :allowed-dates="allowedDates"
                        ></v-date-picker>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="grey"
                            @click="holiday.startDateMenu = false"
                            >Zavrieť</v-btn
                          >
                          <v-btn
                            text
                            color="purple"
                            @click="resetDate(holiday, 'startDate')"
                            >Resetovať</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="holiday.endDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ props }">
                        <v-text-field
                          v-model="holiday.endDateFormatted"
                          label="Do"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          variant="underlined"
                          density="compact"
                          v-bind="props"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-card>
                        <v-date-picker
                          v-model="holiday.endDate"
                          @update:model-value="updateDate(holiday, 'endDate')"
                          :landscape="true"
                          class="custom-date-picker input-field"
                          :allowed-dates="allowedDates"
                        ></v-date-picker>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="grey"
                            @click="holiday.endDateMenu = false"
                            >Zavrieť</v-btn
                          >
                          <v-btn
                            text
                            color="purple"
                            @click="resetDate(holiday, 'endDate')"
                            >Resetovať</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="11">
                    <v-text-field
                      v-model="holiday.description"
                      label="Popis"
                      variant="underlined"
                      density="compact"
                      class="small-field"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" class="d-flex align-center justify-end">
                    <v-btn icon @click="removeHoliday(index)">
                      <v-icon>mdi-minus-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <v-row>
                <v-col cols="12" class="d-flex justify-start">
                  <v-btn text @click="addHoliday" class="text-button mb-4">
                    + Pridať prázdniny
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="d-flex justify-start">
                  <v-btn
                    @click="saveAllHolidays"
                    variant="outlined"
                    class="mb-4"
                    >Uložiť všetky prázdniny</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
            {{ snackbarText }}
            <template v-slot:actions>
                <v-btn color="white" variant="text" @click="snackbar = false">
                    Zavrieť
                </v-btn>
            </template>
        </v-snackbar>
  </v-container>
</template>
  
  <script>
import { ref, onMounted, watch, computed } from "vue";
import Service from "@/api/services";

export default {
  name: "HolidaySettings",
  setup() {
    const showHolidaySettings = ref(false);
    const holidaysToAdd = ref([
      {
        startDate: new Date(),
        endDate: new Date(),
        description: "",
        startDateMenu: false,
        endDateMenu: false,
        startDateFormatted: "",
        endDateFormatted: "",
      },
    ]);
    const holidays = ref([]);
    const schoolId = ref(null);
    const selectedSchool = ref("");
    const schoolOptions = ref([]);
    const loadingSchools = ref(false);
    const snackbar = ref(false);
    const snackbarText = ref("");
    const snackbarColor = ref("success");

    const toggleHolidaySettings = () => {
      showHolidaySettings.value = !showHolidaySettings.value;
    };

    const addHoliday = () => {
  holidaysToAdd.value.push({
    startDate: null,
    endDate: null,
    startDateFormatted: "",
    endDateFormatted: "",
    description: "",
    startDateMenu: false,
    endDateMenu: false,
  });
};

    const removeHoliday = (index) => {
      holidaysToAdd.value.splice(index, 1);
    };

    const saveHoliday = async (holiday) => {
      if (!schoolId.value) {
        showSnackbar("Prosím, vyberte školu", "error");
        return;
      }
      try {
        const response = await Service.createHoliday(schoolId.value, {
          start_date: holiday.startDate,
          end_date: holiday.endDate,
          description: holiday.description,
        });
        holidays.value.push(response);
        showSnackbar("Prázdniny boli úspešne uložené", "success");
        return response;
      } catch (error) {
        console.error("Error saving holiday:", error);
        showSnackbar("Chyba pri ukladaní prázdnin", "error");
        throw error;
      }
    };

    const saveAllHolidays = async () => {
  if (holidaysToAdd.value.length === 0 && !holidays.value.some(h => h.isModified)) {
    showSnackbar("Nie sú žiadne nové alebo upravené prázdniny na uloženie", "info");
    return;
  }
  try {
    // Save new holidays
    for (let holiday of holidaysToAdd.value) {
      await saveHoliday(holiday);
    }
    
    // Update modified existing holidays
    for (let holiday of holidays.value) {
      if (holiday.isModified) {
        console.log('Saving modified holiday:', holiday);
        await updateHoliday(holiday);
      }
    }
    
    holidaysToAdd.value = []; // Clear holidaysToAdd after saving
    await fetchHolidays(); // Refetch holidays without adding an empty form
    showSnackbar("Všetky prázdniny boli úspešne uložené", "success");
  } catch (error) {
    console.error("Error saving holidays:", error);
    showSnackbar("Chyba pri ukladaní prázdnin", "error");
  }
};

const updateDate = (holiday, dateType) => {
  const date = holiday[dateType];
  if (date) {
    holiday[dateType] = date instanceof Date ? date : new Date(date);
    holiday[`${dateType}Formatted`] = formatDate(holiday[dateType]);
  } else {
    holiday[dateType] = null;
    holiday[`${dateType}Formatted`] = "";
  }
  holiday[`${dateType}Menu`] = false;
  holiday.isModified = true;
};
    // Add this function to handle description changes
    const updateDescription = (holiday) => {
      holiday.isModified = true;
    };

    const fetchHolidays = async () => {
  if (!schoolId.value) return;
  try {
    const response = await Service.getHolidays(schoolId.value);
    holidays.value = response.map(holiday => ({
      ...holiday,
      startDate: new Date(holiday.start_date),
      endDate: new Date(holiday.end_date),
      startDateFormatted: formatFetchDate(holiday.start_date),
      endDateFormatted: formatFetchDate(holiday.end_date),
      startDateMenu: false,
      endDateMenu: false
    }));
    if (holidays.value.length === 0 && showHolidaySettings.value) {
      showSnackbar("Pre túto školu zatiaľ nie sú nastavené žiadne prázdniny.", "info");
    }
    holidaysToAdd.value = []; // Clear holidaysToAdd when fetching existing holidays
  } catch (error) {
    console.error("Error fetching holidays:", error);
    showSnackbar("Chyba pri načítaní prázdnin", "error");
  }
};

    const formatFetchDate = (date) => {
      if (!date) return "";

      let d;
      if (typeof date === "string") {
        // Remove the 'T' and everything after the seconds
        const cleanDate = date.split("T")[0];
        const [year, month, day] = cleanDate.split("-");
        d = new Date(year, month - 1, day); // month is 0-indexed in Date constructor
      } else if (date instanceof Date) {
        d = date;
      } else {
        console.error("Invalid date format:", date);
        return "";
      }

      return `${d.getDate().toString().padStart(2, "0")}.${(d.getMonth() + 1)
        .toString()
        .padStart(2, "0")}.${d.getFullYear()}`;
    };

    const fetchSchools = async () => {
      loadingSchools.value = true;
      try {
        const response = await Service.getSchools();
        schoolOptions.value = response.map((school) => ({
          value: school.id,
          title: school.name,
        }));
        if (schoolOptions.value.length > 0) {
          selectedSchool.value = schoolOptions.value[0].value;
          schoolId.value = selectedSchool.value;
        }
      } catch (error) {
        console.error("Error fetching schools:", error);
        showSnackbar("Chyba pri načítaní škôl", "error");
      } finally {
        loadingSchools.value = false;
      }
    };

    const showSnackbar = (text, color = "success") => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    watch(selectedSchool, (newValue) => {
      const school = schoolOptions.value.find((s) => s.name === newValue);
      schoolId.value = school ? school.id : null;
      fetchHolidays();
    });
    onMounted(async () => {
      await fetchSchools();
      if (selectedSchool.value) {
        schoolId.value = selectedSchool.value;
        await fetchHolidays();
      }
    });

    const resetDate = (holiday, dateType) => {
      if (dateType === "startDate") {
        holiday.startDate = null;
        holiday.startDateFormatted = "";
      } else if (dateType === "endDate") {
        holiday.endDate = null;
        holiday.endDateFormatted = "";
      }
    };

    const formatDate = (date) => {
  if (!date) return '';
  
  let d;
  if (typeof date === 'string') {
    // Remove the 'T' and everything after the seconds
    const cleanDate = date.split('T')[0];
    const [year, month, day] = cleanDate.split('-');
    d = new Date(year, month - 1, day);  // month is 0-indexed in Date constructor
  } else if (date instanceof Date) {
    d = date;
  } else {
    console.error('Invalid date format:', date);
    return '';
  }
  
  return `${d.getDate().toString().padStart(2, '0')}.${(d.getMonth() + 1).toString().padStart(2, '0')}.${d.getFullYear()}`;
};

const updateHoliday = async (holiday) => {
  if (!schoolId.value) {
    showSnackbar('Prosím, vyberte školu', 'error');
    return;
  }
  try {
    console.log('Updating holiday:', holiday);
    
    // Adjust the dates to account for timezone differences
    const adjustDate = (date) => {
      const d = new Date(date);
      return new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().split('T')[0];
    };

    const updatedHoliday = await Service.updateHoliday(schoolId.value, holiday.id, {
      start_date: adjustDate(holiday.startDate),
      end_date: adjustDate(holiday.endDate),
      description: holiday.description
    });

    const index = holidays.value.findIndex(h => h.id === updatedHoliday.id);
    if (index !== -1) {
      holidays.value[index] = {
        ...updatedHoliday,
        startDate: new Date(updatedHoliday.start_date),
        endDate: new Date(updatedHoliday.end_date),
        startDateFormatted: formatDate(updatedHoliday.start_date),
        endDateFormatted: formatDate(updatedHoliday.end_date),
        startDateMenu: false,
        endDateMenu: false,
        isModified: false
      };
    }
    showSnackbar('Prázdniny boli úspešne aktualizované', 'success');
  } catch (error) {
    console.error('Error updating holiday:', error);
    showSnackbar('Chyba pri aktualizácii prázdnin', 'error');
  }
};

    const newHoliday = ref({
      startDate: new Date(),
      endDate: new Date(),
      description: "",
      startDateMenu: false,
      endDateMenu: false,
      startDateFormatted: formatDate(new Date()),
      endDateFormatted: formatDate(new Date()),
    });

    const deleteHoliday = async (holidayId) => {
      try {
        await Service.deleteHoliday(schoolId.value, holidayId);
        holidays.value = holidays.value.filter((h) => h.id !== holidayId);
        showSnackbar("Prázdniny boli úspešne vymazané", "success");

        // Add this check
        if (holidays.value.length === 0) {
          addHoliday();
        }
      } catch (error) {
        console.error("Error deleting holiday:", error);
        showSnackbar("Chyba pri mazaní prázdnin", "error");
      }
    };

    const onSchoolChange = (newValue) => {
      schoolId.value = newValue;
      fetchHolidays();
    };

    const currentMonth = ref(new Date());

    const allowedDates = computed(() => {
      return (date) => {
        // Convert the input date to a comparable format
        const testDate = new Date(date);
        testDate.setHours(0, 0, 0, 0);

        // Check if the date falls within any existing holiday
        for (const holiday of holidays.value) {
          const startDate = new Date(holiday.startDate);
          const endDate = new Date(holiday.endDate);
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(0, 0, 0, 0);

          if (testDate >= startDate && testDate <= endDate) {
            return false; // Date is within a holiday, so it's not allowed
          }
        }
        return true; // Date is not within any holiday, so it's allowed
      };
    });

    const updateCurrentMonth = (newDate) => {
      currentMonth.value = new Date(newDate);
    };

    return {
      showHolidaySettings,
      holidaysToAdd,
      holidays,
      selectedSchool,
      schoolOptions,
      toggleHolidaySettings,
      addHoliday,
      removeHoliday,
      saveHoliday,
      saveAllHolidays,
      snackbar,
      snackbarText,
      snackbarColor,
      loadingSchools,
      formatDate,
      updateDate,
      resetDate,
      updateHoliday,
      deleteHoliday,
      newHoliday,
      onSchoolChange,
      updateDescription,
      allowedDates,
      updateCurrentMonth
    };
  },
};
</script>
  
  <style scoped>
.v-btn {
  text-transform: none;
}

.text-h6 {
  font-weight: 500;
  color: #333;
}

.clickable {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-card-text {
  padding-top: 0;
}

.v-btn {
  margin-top: 8px;
  margin-bottom: 8px;
}

.small-field {
  max-width: 300px;
}

.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent;
  box-shadow: none;
}

.holiday-form {
  margin-bottom: 20px;
}
</style>