import store from '@/store';
import axios from 'axios';


//const API_URL = 'http://51.75.62.15:8008/';
//const API_URL = 'http://localhost:8000/';
const API_URL = '/api/';
//const ip_url = '51.75.62.15:8080'
//const ip_url = 'localhost:8080'

class Services {
  // Register service
  async register(userData) {
    try {
      const token = localStorage.getItem('accessToken'); // Retrieve the access token from local storage
      const response = await axios.post(`${API_URL}auth/register`, userData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status) {
        return response.status;
      } else {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        return error.response.status;
      } else {
        throw error.response ? error.response.data : error;
      }
    }
  }
  
  async registerParent(userData) {
    try {
      const token = localStorage.getItem('accessToken'); // Retrieve the access token from local storage
      const response = await axios.post(`${API_URL}auth/register`, userData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response) {
        return response;
      } else {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        return error.response.status;
      } else {
        throw error.response ? error.response.data : error;
      }
    }
  }
  
  async deleteSubsidy(subsidy_id) {
    try {
      const token = localStorage.getItem('accessToken'); // Retrieve the access token from local storage
      const response = await axios.delete(`${API_URL}subsidy/subsidies/${subsidy_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 204) {
        return { success: true };
      } else {
        throw new Error('Failed to delete subsidy');
      }
    } catch (error) {
      console.error('Error deleting subsidy:', error);
      throw error;
    }
  }
  
  async searchParentByEmail(email) {
    try {
      const token = localStorage.getItem('accessToken'); // Retrieve the access token from local storage
      const response = await axios.get(`${API_URL}user/parents/search`, {
        params: { email },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error searching for parent:', error);
      throw error;
    }
  }

  async assignChildToParent(parentId, childId) {
    try {
      const token = localStorage.getItem('accessToken'); // Retrieve the access token from local storage
      console.log("Parent ID", parentId);
      console.log("Child ID", childId);
      const response = await axios.post(`${API_URL}user/assign-child`, { parent_id: parentId, child_id: childId }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error assigning child to parent:', error);
      throw error;
    }
  }
  
  async checkSchoolSubsidyWarnings(schoolId, subsidyData) {
    try {
      const token = localStorage.getItem('accessToken'); // Retrieve the access token from local storage
      const response = await axios.post(`${API_URL}subsidy/schools/${schoolId}/subsidy/check`, subsidyData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error checking school subsidy warnings:', error);
      throw error;
    }
  }
  
  async getAllDocuments(params = {}) {
    try {
      const token = localStorage.getItem('accessToken'); // Retrieve the access token from local storage
      const response = await axios.get(`${API_URL}documents/templates`, {
        params: {
          document_type: params.documentType,
          status: params.status,
          search: params.search,
          start_date: params.startDate,
          end_date: params.endDate,
          page: params.page || 1,
          items_per_page: params.itemsPerPage || 10
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      return {
        items: response.data.items,
        total: response.data.total,
        page: response.data.page,
        itemsPerPage: response.data.items_per_page,
        totalPages: response.data.total_pages
      };
    } catch (error) {
      console.error('Error fetching documents:', error);
      throw error;
    }
  }
  
  async searchMeals(query, supplierId) {
    try {
      const token = localStorage.getItem('accessToken'); // Retrieve the access token from local storage
      const response = await axios.get(`${API_URL}meal/meals/search`, {
        params: { query, supplier_id: supplierId },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error searching meals:', error);
      throw error;
    }
  }
  
  async getRecentMeals(supplierId, limit = 10) {
    try {
      const token = localStorage.getItem('accessToken'); // Retrieve the access token from local storage
      const response = await axios.get(`${API_URL}meal/meals/recent`, {
        params: {
          supplier_id: supplierId,
          limit: limit
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching recent meals:', error);
      throw error;
    }
  }

  async getParentId(userId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}user/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const user = response.data;
      console.log(user);
      if (user.user_type === 'student') {
        // If the user is a student, fetch their parent's ID
        const parentResponse = await axios.get(`${API_URL}user/students/${userId}/parent`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        console.log("PARENT RESPONSE", parentResponse);
        return parentResponse.data.parent_id;
      } else {
        // If the user is not a student, return their own ID
        return userId;
      }
    } catch (error) {
      console.error('Error fetching parent ID:', error);
      throw error;
    }
  }
  
  async updateMeal(id, mealData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(`${API_URL}meal/meals/${id}`, mealData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating meal:', error);
      throw error;
    }
  }
  
  async uploadMenu(formData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${API_URL}meal/upload-menu`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading menu:', error);
      throw error;
    }
  }
  
  async submitExtractedMeals(meals, supplierId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${API_URL}meal/submit-extracted-meals`, { meals, supplier_id: supplierId }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error submitting extracted meals:', error);
      throw error;
    }
  }
  
  async getSupplierByEmail(email) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}user/suppliers/by-email/${email}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching supplier by email:', error);
      throw error;
    }
  }
  
  async uploadDocument(file, userId) {
    try {
      const token = localStorage.getItem('accessToken');
      const formData = new FormData();
      formData.append('file', file);
      formData.append('user_id', userId);
  
      const response = await axios.post(`${API_URL}documents/documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Error uploading document:', error);
      throw error;
    }
  }

  async createEmailTemplate(templateData) {
    try {
      const token = localStorage.getItem('accessToken');
      console.log(templateData);
  
      const response = await axios.post(`${API_URL}email/email-templates-system/create/`, templateData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error creating email template:', error);
      throw error;
    }
  }
  
  async updateEmailTemplate(templateId, updateData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(`${API_URL}email/email-templates-system/update/${templateId}/`, updateData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating email template:', error);
      throw error;
    }
  }
  
  async getSystemEmailTemplates() {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}email/email-templates-system/system/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching system email templates:', error);
      throw error;
    }
  }
  
  async deleteEmailTemplate(templateId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.delete(`${API_URL}email/email-templates-system/${templateId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting email template:', error);
      throw error;
    }
  }
  
  async getMealsForSchoolAndDate(schoolId, date, childId) {
    try {
      const token = localStorage.getItem('accessToken');
      let formattedDate;
      if (date instanceof Date) {
        formattedDate = date.toISOString().split('T')[0];
      } else if (typeof date === 'string') {
        formattedDate = date; // Assume the date is already in YYYY-MM-DD format
      } else {
        throw new Error('Invalid date format');
      }
      
      const response = await axios.get(`${API_URL}meal/meals/${schoolId}/${formattedDate}/${childId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data.map(meal => ({
        ...meal,
        full_price: meal.full_price,
        half_price: meal.half_price,
        // Remove the price_student field if it's no longer needed
        // price_student: undefined,
      }));
    } catch (error) {
      console.error('Error fetching meals for school and date:', error);
      throw error;
    }
  }
  
  async updateOrderItems(orderId, items) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(`${API_URL}meal/orders/${orderId}/items`, items, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating order items:', error);
      throw error;
    }
  }
  
  async getOrderItems(orderId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}meal/orders/${orderId}/items`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching order items:', error);
      throw error;
    }
  }
  
  async getEmployeeById(employeeId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}user/employees/${employeeId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching employee details:', error);
      throw error;
    }
  }
  
  async getManagerById(managerId) {
    try {
      const token = localStorage.getItem('accessToken');
      console.log(managerId);
      const response = await axios.get(`${API_URL}user/managers/${managerId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching manager:', error);
      throw error.response ? error.response.data : error.message;
    }
  }

  async addCredits(userId, amount = 20) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${API_URL}credit/`, {
        user_id: userId,
        amount: amount,
        credit_due_date: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0] // Set due date to one year from now
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error adding credits:', error);
      throw error;
    }
  }
  
  async getEmployees(page, itemsPerPage, schoolId = null) {
    try {
      const token = localStorage.getItem('accessToken');
      const params = {
        page: page,
        items_per_page: itemsPerPage
      }
  
      if (schoolId != null) {
        params.school_id = schoolId
      }
  
      const response = await axios.get(`${API_URL}user/employees/`, {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching employees:', error);
      throw error;
    }
  }
  
  async getManagers(page = 1, itemsPerPage = 10, schoolId = null) {
    try {
      const token = localStorage.getItem('accessToken');
      const params = {
        page,
        items_per_page: itemsPerPage,
      };
  
      if (schoolId !== null) {
        params.school_id = schoolId;
      }
  
      const response = await axios.get(`${API_URL}user/managers`, {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
  
      return response.data;
    } catch (error) {
      console.error('Error fetching managers:', error);
      throw error;
    }
  }
  
  async addManager(managerData) {
    try {
      const token = localStorage.getItem('accessToken');
      const params = {
        first_name: managerData.first_name,
        last_name: managerData.last_name,
        email: managerData.email,
        school_ids: managerData.schools,
        phone_number: managerData.phone_number // Assuming schools is an array of school objects
      };
      console.log(params);
      const response = await axios.post(`${API_URL}user/managers/`, params, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error adding manager:', error);
      throw error.response ? error.response.data : error.message;
    }
  }
  
  async updateEmployee(employeeId, employeeData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(`${API_URL}user/employees/${employeeId}`, employeeData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating employee:', error);
      throw error;
    }
  }
  
  async createEmployee(employeeData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${API_URL}user/employees/`, employeeData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error creating employee:', error);
      throw error;
    }
  }
  
  async getUserCredits(userId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}credit/user/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log("Credit response for user", userId, ":", response.data);
      return response.data;
    } catch (error) {
      console.error(`Error fetching credits for user ${userId}:`, error);
      // Return an empty array instead of throwing an error
      return [];
    }
  }

  // Login service
  async login(username, password) {
    console.log(API_URL);
    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);
  
      const response = await axios.post(`${API_URL}auth/login`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      console.log(response);
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      if (response.data) {
        return response.data;
      } else {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async refreshToken(refresh) {
    try {
      console.log(refresh);
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${API_URL}auth/refresh-token`, { refresh_token: refresh }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async logout(logoutUrlEncoded) {
    console.log(logoutUrlEncoded);
    const idToken = store.state.id_token; // Assuming the ID token is stored in Vuex state
    const logoutLink = `https://skola.quantify.sk:8443/realms/RS/protocol/openid-connect//logout?post_logout_redirect_uri=https://skola.quantify.sk/login&refresh_token=${store.state.refreshToken}&id_token_hint=${idToken}&client_id=web-app`;
    console.log(logoutLink);
    
    // Redirect to the logout URL
    window.location.href = logoutLink;
  }
  
  async setPassword(password, email, old_password) {
    try {
      console.log(password)
      console.log(email)
      console.log(old_password)
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${API_URL}auth/set-password`, { new_password: password, email: email, old_password: old_password }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.status;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async userSetLoginPassword(password, email) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${API_URL}auth/set-password/login`, { new_password: password, email: email }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.status;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async getUserIdByEmail(email) {
    try {
      console.log("EMAIL", email);
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}user/user-id-by-email/`, {
        params: { email },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      console.log(response.data);
      return response.data.user_id;
    } catch (error) {
      console.error('Error fetching user ID:', error);
      throw error.response ? error.response.data : error;
    }
  }
  
  async getMeals(parentId) {
    try {
      console.log("I am here", parentId);
      const token = localStorage.getItem('accessToken');
      const params = {
        parent_id: parentId
      };
      const response = await axios.get(`${API_URL}meal/parent/meals/`, {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
  
      return response.data;
    } catch (error) {
      console.error('Error fetching meals:', error);
      throw error;
    }
  }
  
  async getNotifications(userId = null, email = null) {
    try {
      const token = localStorage.getItem('accessToken');
      let url = `${API_URL}notification/notifications`;
      const params = {};
      if (userId) {
        params.user_id = userId;
      } else if (email) {
        params.email = email;
      }
      const response = await axios.get(url, {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  }
  
  async forgotPassword(email) {
    try {
      const response = await axios.post(`${API_URL}auth/forgot-password`, { email });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async getSchoolByName(schoolName) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}school/schools/by-name/${encodeURIComponent(schoolName)}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching school by name:', error);
      if (error.response) {
        throw new Error(error.response.data.detail || 'Error fetching school by name');
      } else if (error.request) {
        throw new Error('No response received from server');
      } else {
        throw new Error('Error setting up the request');
      }
    }
  }
  
  async resetPassword(newPassword, token, email) {
    try {
      console.log(newPassword);
      console.log(token);
      console.log(email);
      const response = await axios.post(`${API_URL}auth/reset-password`, { new_password: newPassword, reset_token: token, email: email });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getSchools(minRange, maxRange) {
    try {
      const token = localStorage.getItem('accessToken');
      const params = {
        min_students: minRange,
        max_students: maxRange
      };
      const response = await axios.get(`${API_URL}school/`, {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async getSchool(schoolId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}school/read/${schoolId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      console.log(response);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async createSchool(schoolData) {
    try {
      const token = localStorage.getItem('accessToken');
      console.log("School Data: ", schoolData);
      const response = await axios.post(`${API_URL}school/`, {
        name: schoolData.name,
        address: schoolData.address,
        email: schoolData.email,
        phone_number: schoolData.phone_number,
        manager_name: schoolData.manager_id
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      console.log(response);
  
      // Check for successful status codes (both 200 and 201)
      if (response.status === 200 || response.status === 201) {
        console.log("School created successfully");
        return response.data;
      } else {
        // For any other status codes, throw an error
        throw new Error(`Unexpected status code: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error adding school:', error);
      throw error;
    }
  }
  
  async downloadDocument(documentId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}documents/documents/${documentId}/download`, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response;
    } catch (error) {
      console.error('Error downloading document:', error);
      throw error;
    }
  }
  
  async getUserPayments(userId, params = {}) {
    try {
      const token = localStorage.getItem('accessToken');
      console.log("User ID", userId);
      console.log("Params", params);
      const response = await axios.get(`${API_URL}payment/users/${userId}/payments`, {
        params: {
          start_date: params.startDate,
          end_date: params.endDate,
          status: params.status,
          payment_type: params.paymentType,
          search: params.search,
          page: params.page,
          items_per_page: params.itemsPerPage
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user payments:', error);
      throw error;
    }
  }
  
  async getAllergens() {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(`${API_URL}meal/allergens/`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  }
  
  async getMealsForSchoolAndWeek(schoolId, weekStart) {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(`${API_URL}meal/meals/for/school`, {
      params: {
        school_id: schoolId,
        start_date: weekStart
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  
    return response.data;
  }
  
  async getMealsForSchoolAndMonth(schoolId, year, month, child_id = null) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}meal/meals/school/${schoolId}/month`, {
        params: { schoolId, year, month, child_id },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
  
      return response.data;
    } catch (error) {
      console.error('Error fetching meals for school and month:', error);
      throw error;
    }
  }
  
  async getStudentsWithSubsidies(page = 1, itemsPerPage = 10) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}subsidy/students/with-subsidies`, {
        params: {
          page: page,
          items_per_page: itemsPerPage
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching students with subsidies:', error);
      throw error;
    }
  }
  
  async getSchoolSubsidy(schoolId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}subsidy/schools/${schoolId}/subsidy`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching school subsidy:', error);
      throw error;
    }
  }
  
  async updateSchoolSubsidy(schoolId, subsidyData) {
    try {
      const token = localStorage.getItem('accessToken');
      console.log("Calling update school subsidy")
      const response = await axios.put(`${API_URL}subsidy/schools/${schoolId}/subsidy`, subsidyData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating school subsidy:', error);
      throw error;
    }
  }
  
  async updateStudentSubsidy(studentId, amount) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(`${API_URL}subsidy/students/${studentId}/subsidy`, { amount }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating student subsidy:', error);
      throw error;
    }
  }
  
  async searchStudents(search) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}student/students/search/`, {
        params: { q: search },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error searching students:', error);
      throw error;
    }
  }

  async createStudentSubsidy(studentId, amount) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${API_URL}subsidy/students/${studentId}/subsidy/${amount}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error creating student subsidy:', error);
      throw error;
    }
  }
  
  async getStudentIdByName(name) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}student/students/search-by-name/`, {
        params: { name },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error searching for student:', error);
      throw error;
    }
  }
  
  async getUserDocuments(userId, params) {
    const token = localStorage.getItem('accessToken');
    const { startDate, endDate, status, search, page, itemsPerPage } = params;
    const response = await axios.get(`${API_URL}documents/users/${userId}/documents`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        status,
        search,
        page,
        items_per_page: itemsPerPage
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  }
  
  async deleteMealSelectionForDate(studentId, date) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.delete(`${API_URL}student/meal-selections/${date}`, {
        params: { student_id: studentId },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting meal selection:", error);
      throw error; // Re-throw the error so it can be handled by the caller
    }
  }
  
  async createStudentMealSelection(selectionData) {
    try {
      const token = localStorage.getItem('accessToken');
      console.log(selectionData);
      const response = await axios.post(`${API_URL}student/student-meal-selection-assign/`, selectionData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response);
      return response.data;
    } catch (error) {
      console.error('Error creating meal selection:', error);
      throw error;
    }
  }
  
  async addOrderItem(orderItemData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${this.baseURL}/order-items/`, orderItemData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error adding order item:', error);
      throw error;
    }
  }
  
  async getMealsForSupplier(supplierId, startDate) {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(`${API_URL}meal/${supplierId}/meals`, {
      params: {
        supplier_id: supplierId, 
        start_date: startDate
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  }
  
  async deleteMeal(mealId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.delete(`${API_URL}meal/${mealId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting meal:', error);
      throw error;
    }
  }
  
  async updateSchool(schoolId, schoolData) {
    try {
      const token = localStorage.getItem('accessToken');
      console.log("School Data: ", schoolData);
      const response = await axios.put(`${API_URL}school/${schoolId}`, {
        name: schoolData.name,
        address: schoolData.address,
        email: schoolData.contact_email,
        phone_number: schoolData.contact_phone,
        manager_id: schoolData.manager
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log("Manager updated", response);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async getSchoolManager(schoolId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}school/schools/${schoolId}/manager`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching school manager:', error);
      if (error.response) {
        throw new Error(error.response.data.detail || 'Error fetching school manager');
      } else if (error.request) {
        throw new Error('No response received from server');
      } else {
        throw new Error('Error setting up the request');
      }
    }
  }
  
  async searchEmployeesBySchool(schoolId, query) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}school/schools/${schoolId}/employees/search`, {
        params: { q: query },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error searching employees:', error);
      if (error.response) {
        throw new Error(error.response.data.detail || 'Error searching employees');
      } else if (error.request) {
        throw new Error('No response received from server');
      } else {
        throw new Error('Error setting up the request');
      }
    }
  }
  
  async getStudentDetails(studentId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}student/students/${studentId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching student details:', error);
      throw error;
    }
  }
  
  async updateStudent(studentData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(`${API_URL}student/students/${studentData.id}`, studentData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      console.error('Error updating student:', error);
      return {
        success: false,
        message: error.response?.data?.detail || 'An error occurred while updating the student'
      };
    }
  }
  
  async getSchoolIdByName(schoolName) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}school/schools/${schoolName}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      console.log(response);
      return response.data.id; // Assuming the API returns an object with an 'id' field
    } catch (error) {
      console.error('Error fetching school ID:', error);
      throw error.response?.data || error.message;
    }
  }
  

  async addStudent(student) {
    try {
      const token = localStorage.getItem('accessToken');
      const params = {
        user: {
          firstName: student.user.firstName,
          lastName: student.user.lastName,
          email: student.user.email,
          hashed_password: student.user.hashed_password,
          user_type: 'STUDENT',
          phone_number: student.user.phone_number || '',
          date_of_birth: student.date_of_birth
        },
        grade: student.grade,
        classroom: student.classroom,
        studyForm: student.studyForm,
        schools: student.schools
      };
  
      console.log('Student data being sent:', params);
      const response = await axios.post(`${API_URL}student/students`, params, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.data.success) {
        console.log('Student added successfully:', response.data);
        return response.data;
      } else {
        throw new Error(response.data.message || "Failed to add student");
      }
    } catch (error) {
      console.error('Error adding student:', error);
      throw error;
    }
  }
  
  async updateDiner(id, dinerData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(`${API_URL}student/update/${id}`, {
        first_name: dinerData.first_name,
        last_name: dinerData.last_name,
        email: dinerData.email,
        classroom: dinerData.classroom,
        grade: dinerData.grade,
        study_form: dinerData.study_form,
        tuiton_payed: dinerData.tuiton_payed,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async deleteSchool(schoolId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.delete(`${API_URL}school/${schoolId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async updateManager(managerData) {
    try {
      const token = localStorage.getItem('accessToken');
      console.log(managerData);
      // Get school ids by name from backend and fetch managerData.schoolIds are names
      const school_ids = await Promise.all(managerData.schoolIds.map(async (school_name) => {
        return await this.getSchoolId(school_name);
      }));
  
      const params = {
        first_name: managerData.name.split(' ')[0],
        last_name: managerData.name.split(' ')[1],
        email: managerData.email,
        phone_number: managerData.phone_number || '',
        schoolIds: school_ids
      };
  
      console.log(params);
      const response = await axios.put(`${API_URL}user/managers/${managerData.id}`, params, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      console.error('Error updating manager:', error);
      throw error.response ? error.response.data : error.message;
    }
  }
  
  async getUsers(userType, selectedSchool, page, itemsPerPage) {
    try {
      const token = localStorage.getItem('accessToken');
      const params = { user_type: userType };
      if (selectedSchool !== null) {
        params.school_id = selectedSchool.data.id;
      }
      params.limit = itemsPerPage;
      console.log(params);
      const response = await axios.get(`${API_URL}user/`, {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async getSchoolId(schoolName) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}school/`, {
        params: { name: schoolName },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data[0].id;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async getSchoolById(schoolId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}school/read/${schoolId}`, {
        params: { school_id: schoolId },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async updateSupplierSettings(supplierData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(`${API_URL}user/suppliers/${supplierData.supplierId}/settings`, {
        schools: supplierData.schools,
        order_modification_limit_time: supplierData.order_modification_limit_time,
        send_order_by_email: supplierData.send_order_by_email,
        time_of_sending_order: supplierData.time_of_sending_order,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating supplier settings:', error);
      throw error;
    }
  }
  
  async updateSupplierData(supplierData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(`${API_URL}user/suppliers/update`, supplierData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating supplier data:', error);
      throw error;
    }
  }

  async getMealsAndOrdersForWeek(supplierId, weekStart, schoolId, order_id = null) {
    try {
      const token = localStorage.getItem('accessToken');
      const params = {
        school_id: schoolId,
        week_start: weekStart
      };
      
      if (supplierId != null) {
        params.supplier_id = supplierId;
      }
  
      if (order_id != null) {
        params.order_id = order_id;
      }
      
      console.log(params);
      const response = await axios.get(`${API_URL}meal/menu/week`, {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async getSuppliers(page = 1, itemsPerPage = 10, schoolId = null) {
    try {
      const token = localStorage.getItem('accessToken');
      console.log("Service", schoolId, page, itemsPerPage);
      const params = {
        page,
        items_per_page: itemsPerPage,
      };
  
      if (schoolId !== null) {
        params.school_id = schoolId;
      }
  
      const response = await axios.get(`${API_URL}user/suppliers/all`, {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching suppliers:', error);
      throw error;
    }
  }
  
  async getDiners(dinerTypes = [], selectedSchool = null, page = 1, itemsPerPage = 10) {
    try {
      const token = localStorage.getItem('accessToken');
      let response;
      console.log("Service", page, itemsPerPage, dinerTypes, selectedSchool);
      const params = {
        page,
        items_per_page: itemsPerPage,
      };
  
      if (dinerTypes.length > 0) {
        params.diner_types = dinerTypes.join(',');
      }
  
      if (selectedSchool !== null) {
        params.selected_school = selectedSchool;
      }
  
      response = await axios.get(`${API_URL}user/diners`, {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
  
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async getStudents(page = 1, itemsPerPage = 10, selectedSchool = null, filters = {}) {
    try {
      const token = localStorage.getItem('accessToken'); 
      console.log("Token", token);
      console.log("Service", page, itemsPerPage, selectedSchool, filters);
  
      const params = {
        page: page,
        items_per_page: itemsPerPage,
      };
  
      if (selectedSchool !== null) {
        params.selected_school = selectedSchool;
      }
  
      if (filters != null) {
        if (filters.first_name || filters.lastName) {
          params.search = filters.first_name
        }
        if (filters.email) {
          params.email = filters.email;
        }
        if (filters.classroom) {
          params.classroom = filters.classroom;
        }
        if (filters.studyForm) {
          params.study_form = filters.studyForm;
        }
        if (filters.tuitionPaid) {
          params.tuition_paid = filters.tuitionPaid;
        }
      }
  
      console.log(params);
  
      const response = await axios.get(`${API_URL}student/`, {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
  
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
  
  async getStudentsPayments(page = 1, itemsPerPage = 10, schoolId = null) {
    try {
      const token = localStorage.getItem('accessToken');
      const params = {
        page: page,
        items_per_page: itemsPerPage,
      };
  
      if (schoolId !== null) {
        params.school_id = schoolId;
      }
  
      console.log("Fetching students with params:", params);
  
      const response = await axios.get(`${API_URL}student/payments/`, {
        params: params,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
  
      return response.data;
    } catch (error) {
      console.error("Error fetching students:", error);
      throw error.response ? error.response.data : error.message;
    }
  }
  
  async getDiner(dinerId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}diner/${dinerId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async getStudentByName(studentName) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}student/`, {
        params: { student_name: studentName },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  
  async addMeal(mealData) {
    try {
      const token = localStorage.getItem('accessToken');
      console.log(mealData);
      const response = await axios.post(`${API_URL}meal/`, mealData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error adding meal:', error);
      throw error;
    }
  }
  
  async updateSchoolOverheadCosts(schoolId, costs) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(`${API_URL}school/schools/${schoolId}/overhead-costs`, costs, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating school overhead costs:', error);
      throw error;
    }
  }
  
  async getSchoolOverheadCosts(schoolId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}school/schools/${schoolId}/overhead-costs`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching school overhead costs:', error);
      throw error;
    }
  }
  
  async addSupplier(supplierData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${API_URL}user/suppliers/`, supplierData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error adding supplier:', error);
      throw error;
    }
  }
  
  async getStudentMealSelections(studentId, startDate, endDate) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}student/meal-selections`, {
        params: {
          student_id: studentId,
          start_date: startDate,
          end_date: endDate
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching student meal selections:', error);
      throw error;
    }
  }
  
  async getOrdersForSupplier(supplierId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}user/suppliers/${supplierId}/orders`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching orders for supplier:', error);
      throw error;
    }
  }

  async getMealSelections(studentId, startDate, endDate) {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(`${API_URL}/meals/selections`, {
      params: { student_id: studentId, start_date: startDate, end_date: endDate },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  }
  
  async payMealSelections(userId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${API_URL}student/pay-meal-selections/?user_id=${userId}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.detail || 'An error occurred while paying for meal selections');
      } else if (error.request) {
        throw new Error('No response received from server');
      } else {
        throw new Error('Error setting up the request');
      }
    }
  }
  
  async getAvailableMeals(studentId, selectedDate) {
    const token = localStorage.getItem('accessToken');
    const response = await axios.get(`${API_URL}/meals/available`, {
      params: { student_id: studentId, selected_date: selectedDate },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  }
  
  async selectMeal(studentId, mealId, date) {
    const token = localStorage.getItem('accessToken');
    const response = await axios.post(`${API_URL}/meals/select`, {
      student_id: studentId,
      meal_id: mealId,
      date: date,
      status: "Neuhradené"  // Default status when selecting a meal
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  }
  
  async getChildrenAndSchools(parentId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}user/parent/${parentId}/children-and-schools`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching children and schools:', error);
      throw error;
    }
  }
  
  async getAutoOrderSettings(studentId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}student/${studentId}/settings/auto-order`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching auto-order settings:', error);
      throw error;
    }
  }
  
  async updateChildDiningSchool(childId, schoolId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(`${API_URL}student/${childId}/dining-school`, { school_id: schoolId }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating child dining school:', error);
      throw error;
    }
  }
  
  async updateAutoOrderSettings(childId, enabled, preference, portionSize) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(`${API_URL}student/${childId}/auto-order-settings`, {
        enabled,
        preference,
        portion_size: portionSize
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating auto-order settings:', error);
      throw error;
    }
  }

  async getHolidays(schoolId) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${API_URL}school/schools/${schoolId}/holidays`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error getting holidays:', error);
      throw error;
    }
  }

  async createHoliday(schoolId, holidayData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${API_URL}school/schools/${schoolId}/holidays`, holidayData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error creating holiday:', error);
      throw error;
    }
  }

  async updateHoliday(schoolId, holidayId, holidayData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(`${API_URL}school/schools/${schoolId}/holidays/${holidayId}`, holidayData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating holiday:', error);
      throw error;
    }
  }

  async deleteHoliday(schoolId, holidayId) {
    try {
      const token = localStorage.getItem('accessToken');
      await axios.delete(`${API_URL}school/schools/${schoolId}/holidays/${holidayId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error deleting holiday:', error);
      throw error;
    }
  }

  async cancelMeals(schoolId, date, reason) {
    try {
      console.log("Cancelling meals for school", schoolId, "on date", date, "with reason", reason);
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(`${API_URL}meal/cancel-meals`, null, {
        params: {
          school_id: schoolId,
          date: date,
          reason: reason
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error cancelling meals:', error);
      throw error;
    }
  }

  async createCheckoutSession(userId, amount) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post(
        `${API_URL}stripe/create-checkout-session`,
        {
          user_id: parseInt(userId),
          amount: parseFloat(amount)
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error.response ? error.response.data : error;
    }
  }

  async updatePayment(paymentId, paymentData) {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.put(
        `${API_URL}payment/${paymentId}`,
        {
          status: paymentData.status,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating payment:", error);
      throw error.response ? error.response.data : error;
    }
  }

  
}

export default new Services();
