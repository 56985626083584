<template>
  <v-list-item class="py-2">
    <v-list-item-content class="align-center">
      <v-list-item-title class="text-h6 text-center bold">
        Rodinná Škola
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>

  <v-divider></v-divider>

  <v-list dense nav>
    <v-list-item
      v-for="item in menuItems.filter((item) => item.condition !== false)"
      :key="item.title"
      link
      :to="item.path"
      :class="{
        'v-item--active': isActive(item.path),
        'v-item--inactive': !isActive(item.path),
      }"
    >
      <template v-slot:prepend>
        <v-icon>{{ item.icon }}</v-icon>
      </template>
      <v-list-item-title
        ><strong>{{ item.title }}</strong></v-list-item-title
      >
    </v-list-item>
  </v-list>

  <v-container>
    <v-divider></v-divider>

    <v-spacer></v-spacer>

    <v-menu v-model="menu" :close-on-content-click="false" location="top">
      <template v-slot:activator="{ props }">
        <v-btn
          variant="text"
          :ripple="false"
          v-bind="props"
          class="text-none"
        >
          <v-icon>mdi-account-circle</v-icon>
          <span class="email-text" :style="{ 'user-select': 'none' }">{{
            email
          }}</span>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="navigateToProfile">
          <template v-slot:prepend>
            <v-icon>mdi-key-change</v-icon>
          </template>
          <v-list-item-title>Zmena hesla</v-list-item-title>
        </v-list-item>

        <v-list-item @click="showLogoutConfirm">
          <template v-slot:prepend>
            <v-icon>mdi-logout</v-icon>
          </template>
          <v-list-item-title>Odhlásiť sa</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>

  <ConfirmDialog ref="confirmDialog" />
</template>

<script>
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { computed, ref } from "vue";
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  name: "SidebarNavigation",
  components: {
    ConfirmDialog,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const menu = ref(false);
    const confirmDialog = ref(null);

    const userType = computed(() => store.getters.getUserType);
    const email = computed(() => store.getters.getEmail);
    console.log(store.getters.getUserEmail, store.getters.getUserType);

    const menuItems = computed(() => {
      const items = [
        
        ...(userType.value === "admin"
          ? [
              // {
              //   title: "Uchádzači",
              //   path: "/applicants",
              //   icon: "mdi-account-group",
              // },
              { title: "Domov", path: "/home", icon: "mdi-home" },
              { title: "Obedy", path: "/meals", icon: "mdi-food" },
              { title: "Platby", path: "/payments", icon: "mdi-cash" },
              {
                title: "Dokumenty",
                path: "/documents",
                icon: "mdi-file-document",
              },
              {
                title: "Správa uživateľov",
                path: "/users",
                icon: "mdi-account-multiple",
              },
              { title: "Nastavenia", path: "/settings", icon: "mdi-cog" },
            ]
          : []),
        ...(userType.value === "parent"
          ? [
              { title: "Domov", path: "/home", icon: "mdi-home" },
              { title: "Obedy", path: "/meals", icon: "mdi-food" },
              {
                title: "Dokumenty",
                path: "/documents",
                icon: "mdi-file-document",
              },
              { title: "Platby", path: "/payments", icon: "mdi-cash" },
              { title: "Nastavenia", path: "/settings", icon: "mdi-cog" },
            ]
          : []),
        ...(userType.value === "supplier"
          ? [
              { title: "Objednávky", path: "/home", icon: "mdi-cart" },
              { title: "Obedy", path: "/meals", icon: "mdi-food" },
              { title: "Nastavenia", path: "/settings", icon: "mdi-cog" },
            ]
            : []),
      ];
      return items.filter((item) => item.condition !== false);
    });

    const isActive = (path) => {
      return route.path === path;
    };

    const navigateToProfile = () => {
      router.push("/profile");
      menu.value = false;
    };

    const showLogoutConfirm = async () => {
      menu.value = false;
      const confirmed = await confirmDialog.value.open('Odhlásenie', 'Naozaj sa chcete odhlásiť?');
      if (confirmed) {
        logout();
      }
    };

    const logout = async () => {
      try {
        await store.dispatch('logout');
        if (!store.getters.isAuthenticated) {
          console.log('Logged out successfully');
          showSnackbar('Úspešne ste sa odhlásili', 'success');
          router.push('/login');
        }
      } catch (error) {
        console.error('Error logging out:', error);
        showSnackbar('Chyba pri odhlasovaní', 'error');
      }
    };

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      menuItems,
      isActive,
      email,
      navigateToProfile,
      menu,
      showLogoutConfirm,
      confirmDialog,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>

<style scoped>


body {
  font-family: "Inter", sans-serif;
}

.navigation {
  width: 200px;
  height: 100vh;
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
}

.v-item--active {
  background-color: #e9ecef !important;
}

.v-item--inactive .v-icon,
.v-item--inactive .v-list-item-title {
  color: #b0bec5; /* Grey color */
}

.v-icon {
  color: #333;
}

.text-h6 {
  font-weight: 500;
  color: #333;
}

.bold {
  font-weight: bold;
}

.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.email-text {
  font-size: 14px;
  margin-left: 8px;
  user-select: none;
}

.text-none {
  text-decoration: none;
}

@media (max-width: 450px) {
  .navigation {
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 0;
    border-right: none;
    border-top: 1px solid #dee2e6;
  }
}
</style>
