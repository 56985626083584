<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title>
            <v-row no-gutters align="center">
              <v-col cols="auto" class="mr-2">
                <v-btn icon @click="goBack">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-tabs v-model="activeTab" :grow="$vuetify.display.smAndDown" :centered="!$vuetify.display.smAndDown"
                  dense>
                  <v-tab>Menu</v-tab>
                  <v-tab>Objednávky</v-tab>
                  <v-tab>Údaje</v-tab>
                </v-tabs>
              </v-col>
              <v-col v-if="activeTab === 0 && !showAddForm" cols="12" sm="auto"
                class="d-flex align-center mt-2 mt-sm-0">
                <v-btn block variant="outlined" @click="showAddForm = true">
                  Pridať jedlo
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-window disabled v-model="activeTab">
              <v-window-item :value="activeTab === 0">
                <div v-if="!showAddForm">
                  <v-row>
                    <v-col cols="12" class="d-flex align-center">
                      <v-select v-model="selectedWeek" :items="availableWeeks" item-title="label" item-value="value"
                        label="Vyberte týždeň" variant="solo" density="compact" hide-details></v-select>
                    </v-col>
                  </v-row>
                  <v-card-text v-if="meals.length === 0" class="text-center">
                    <v-alert color="purple" icon="mdi-information" border="left" prominent text class="mx-auto"
                      max-width="500">
                      <span class="text-h8 font-weight-bold">
                        Pre zvolený týždeň nie sú dostupné žiadne jedlá
                      </span>
                    </v-alert>
                  </v-card-text>
                  <template v-if="!isMealsEmpty">
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <div v-if="!showAddForm">
                            <v-skeleton-loader v-if="isLoadingMeals" type="card, card, card"
                              class="mb-6"></v-skeleton-loader>
                            <template v-else-if="!isMealsEmpty">
                              <v-card v-for="(dayMeals, day) in groupedMeals" :key="day" class="mb-6">
                                <v-card-title class="text-h6">
                                  {{ day }}
                                </v-card-title>
                                <v-card-text>
                                  <v-simple-table :loading="isLoadingMeals" class="hidden-sm-and-down">
                                    <template v-slot:default>
                                      <thead>
                                        <tr>
                                          <th class="text-left meal-column">Jedlo</th>
                                          <th class="text-left description-column">Popis</th>
                                          <th class="text-left allergens-column">Alergény</th>
                                          <th class="text-right price-column">Cena od dodávateľa</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <template v-for="(meal, index) in dayMeals" :key="meal.id">
                                          <tr class="meal-row">
                                            <td class="meal-column">{{ meal.name }}</td>
                                            <td class="description-column">{{ meal.description }}</td>
                                            <td class="allergens-column">
                                              <v-chip v-for="allergen in meal.allergens" :key="allergen.id" x-small
                                                class="mr-1 mb-1" color="purple" text-color="white">
                                                {{ allergen.name }}
                                              </v-chip>
                                            </td>
                                            <td class="text-right price-column">{{ formatCurrency(meal.price_supplier)
                                              }}</td>
                                          </tr>
                                          <tr v-if="index < dayMeals.length - 1" class="spacer-row">
                                            <td colspan="5"></td>
                                          </tr>
                                        </template>
                                      </tbody>
                                    </template>
                                  </v-simple-table>

                                  <!-- Mobile version -->
                                  <v-list :loading="isLoadingMeals" class="hidden-md-and-up">
                                    <template v-for="(meal, index) in dayMeals" :key="meal.id">
                                      <v-list-item>
                                        <v-list-item-content>
                                          <v-list-item-title class="font-weight-bold">{{ meal.name
                                            }}</v-list-item-title>
                                          <v-list-item-subtitle>{{ meal.description }}</v-list-item-subtitle>
                                          <v-list-item-subtitle>
                                            <v-chip v-for="allergen in meal.allergens" :key="allergen.id" x-small
                                              class=" mb-1" color="purple" text-color="white">
                                              {{ allergen.name }}
                                            </v-chip>
                                          </v-list-item-subtitle>
                                          <v-list-item-subtitle class="text-right">
                                            Cena: {{ formatCurrency(meal.price_supplier) }}
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider v-if="index < dayMeals.length - 1"></v-divider>
                                    </template>
                                  </v-list>
                                </v-card-text>
                              </v-card>
                            </template>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </div>
                <div v-else>
                  <MealForm :supplier="supplier" @meal-added="handleMealAdded" />
                </div>
              </v-window-item>

              <v-window-item :value="activeTab === 1">
                <v-data-table v-if="!showEditor" :headers="headers" :items="orders" :loading="isLoading"
                  :items-per-page="10" class="elevation-1">
                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.order_date="{ item }">
                    {{ formatDate(item.order_time) }}
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.order_time="{ item }">
                    {{ formatDateToTime(item.order_time) }}
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.send="{ item }">
                    {{ item.email_sent ? "Áno" : "Nie" }}
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.actions="{ item }">
                    <v-btn v-if="item.order_time && isBeforeOrderTime(item.order_time)"
                      @click="showOrderItemsEditor(item.id)" small
                      :disabled="!isBeforeModificationLimit(item.order_time)"
                      :class="{ 'disabled-btn': !isBeforeModificationLimit(item.order_time) }">
                      Upraviť
                    </v-btn>
                    <v-btn v-else @click="showInvoice(item.raw.id)" small>
                      Faktúra
                    </v-btn>
                  </template>
                </v-data-table>
                <EditOrderComponentVue v-if="showEditor" :orderId="selectedOrderId"
                  @changes-saved="handleChangesSaved" />
              </v-window-item>

              <v-window-item :value="activeTab === 2" class="pt-4">
                <EditSupplierForm :supplier="supplier" @supplier-updated="handleSupplierUpdated" />
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false">
          Zavrieť
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import Services from "@/api/services";
import moment from "moment";
import MealForm from "@/components/MealForm.vue";
import EditSupplierForm from "@/components/EditSupplierForm.vue";
import EditOrderComponentVue from './EditOrderComponent.vue';
import { useDisplay } from "vuetify/lib/framework.mjs";

export default {
  components: {
    MealForm,
    EditSupplierForm,
    EditOrderComponentVue
  },
  props: {
    supplier: {
      type: Object,
      required: true,
    },
  },

  emits: ["go-back", "supplier-updated"],
  setup(props, { emit }) {
    const mobile = useDisplay();
    const meals = ref({});
    const orders = ref([]);
    const isLoading = ref(false);
    const activeTab = ref(0);
    const selectedWeek = ref(0);
    const showAddForm = ref(false);
    const isLoadingMeals = ref(false);
    const selectedOrderId = ref(null);
    const showEditor = ref(false);

    const showOrderItemsEditor = (orderId) => {
      console.log("Show order items editor for order:", orderId);
      selectedOrderId.value = orderId;
      showEditor.value = true;
    };

    const handleSupplierUpdated = () => {
      emit("supplier-updated");
      showSnackbar("Údaje dodávateľa boli aktualizované", "success");
    };

    const isMealsEmpty = computed(() => {
      return Object.keys(meals.value).length === 0;
    });

    const headers = [
      { title: "Objednávka", key: "info" },
      { title: "Dátum", key: "order_date" },
      { title: "Odoslané", key: "send" },
      { title: "Čas poslania objednávky", key: "order_time" },
      { title: "Dátum dodania objednávky", key: "delivery_date" },
      { title: "Škola", key: "school" },
      { title: "Počet obedov", key: "total_meals", align: "center" },
      { key: "actions", sortable: false },
    ];

    const availableWeeks = computed(() => {
      const currentDate = new Date();
      const currentWeek = moment(currentDate).week();
      const currentYear = moment(currentDate).year();

      const weeks = [];
      for (let i = 0; i < 4; i++) {
        const weekNumber = currentWeek + i;
        const weekStart = moment()
          .year(currentYear)
          .isoWeek(weekNumber)
          .startOf("isoWeek");
        const weekEnd = moment()
          .year(currentYear)
          .isoWeek(weekNumber)
          .endOf("isoWeek");

        weeks.push({
          label: `${weekStart.format("DD.MM.YYYY")} - ${weekEnd.format(
            "DD.MM.YYYY"
          )}`,
          value: i,
          start: weekStart.format("DD.MM.YYYY"),
          end: weekEnd.format("DD.MM.YYYY"),
        });
      }

      return weeks;
    });

    const formatDateToTime = (date) => {
      return moment(date).format("HH:mm");
    };
    const groupedMeals = computed(() => {
      const groupedMeals = {};
      if (meals.value && Array.isArray(meals.value)) {
        meals.value.forEach((meal) => {
          let day = moment(meal.date)
            .format("dddd DD.MM.YYYY")
            .replace(/^\w/, (c) => c.toUpperCase());

          // Special handling for Štvrtok
          if (day.startsWith("štvrtok")) {
            day = "Štvrtok" + day.slice(7);
          }

          if (!groupedMeals[day]) {
            groupedMeals[day] = [];
          }
          groupedMeals[day].push(meal);
        });
      }
      return groupedMeals;
    });

    const goBack = () => {
      if (showAddForm.value || showEditor.value) {
        if (showEditor.value) {
          showEditor.value = false;
          selectedOrderId.value = null;
          fetchOrders();
          return;
        }
        showAddForm.value = false;
        return;
      }
      emit("go-back");
    };

    const fetchMeals = async () => {
      isLoading.value = true;
      isLoadingMeals.value = true;
      try {
        const startDate =
          availableWeeks.value[selectedWeek.value].label.split(" - ")[0];
        const formattedStartDate = moment(startDate, "DD.MM.YYYY").format(
          "YYYY-MM-DD"
        );
        const supplierId = props.supplier.id;
        const response = await Services.getMealsForSupplier(
          supplierId,
          formattedStartDate
        );
        console.log("Meals Response:", response);
        meals.value = response;
      } catch (error) {
        console.error("Error fetching meals:", error);
        showSnackbar("Chyba pri načítaní jedál", "error");
      } finally {
        isLoading.value = false;
        isLoadingMeals.value = false;
      }
    };

    const fetchOrders = async () => {
      isLoading.value = true;
      try {
        const supplierId = props.supplier.id;
        const response = await Services.getOrdersForSupplier(supplierId);
        console.log("Orders Response:", response);

        if (!response || !Array.isArray(response)) {
          console.error("Invalid orders response:", response);
          return;
        }

        // Process each order
        orders.value = await Promise.all(
          response.map(async (order) => {
            let schoolName = "Neznáma škola";
            try {
              const schoolDetails = await Services.getSchoolById(
                order.school_id
              );
              console.log("School Details:", schoolDetails);
              if (schoolDetails && schoolDetails.name) {
                schoolName = schoolDetails.name;
              }
              console.log("School Name:", schoolName);
            } catch (error) {
              console.error(`Error fetching school ${order.school_id}:`, error);
            }

            return {
              ...order,
              school: schoolName,
              delivery_date: formatDate(moment(order.order_time).add(1, 'days').toDate()),
              info: `Objednávka #${order.id}`,
            };
          })
        );

        console.log("Processed Orders:", orders.value);
      } catch (error) {
        console.error("Error fetching orders:", error);
        showSnackbar("Chyba pri načítaní objednávok", "error");
      } finally {
        isLoading.value = false;
      }
    };

    const formatCurrency = (value) => {
      return new Intl.NumberFormat("sk-SK", {
        style: "currency",
        currency: "EUR",
      }).format(value);
    };

    const isBeforeModificationLimit = (orderTime) => {
      const now = moment();
      const orderDate = moment(orderTime).startOf('day'); // Set to start of the day (midnight)

      // Parse the modification limit time from the supplier
      const [hours, minutes] = props.supplier.order_modification_limit_time.split(':');
      console.log("SUPPPLIER LIMIT TIME", props.supplier.order_modification_limit_time);
      // Combine the order date with the supplier's modification limit time
      const limitTime = props.supplier.order_modification_limit_time
        ? orderDate.clone().set({ hours, minutes })
        : orderDate.clone().endOf('day');

      console.log("Now:", now.format(), "Limit Time:", limitTime.format());

      console.log("Is before:", now.isBefore(limitTime));
      return now.isBefore(limitTime);
    };

    const formatDate = (date) => {
      return moment(date).format("DD.MM.YYYY");
    };

    const formatTime = (time) => {
      return moment(time, "HH:mm:ss").format("HH:mm");
    };

    const isBeforeOrderTime = (orderTime) => {
      const now = moment();
      console.log("Now:", now);
      console.log("Order Time:", orderTime);
      return now.isBefore(orderTime);
    };

    const editOrder = (orderId) => {
      console.log("Edit order:", orderId);
      showSnackbar("Úprava objednávky bola spustená", "info");
    };

    const showInvoice = (orderId) => {
      console.log("Show invoice for order:", orderId);
      showSnackbar("Zobrazovanie faktúry", "info");
    };

    const handleMealAdded = () => {
      showSnackbar("Jedlo bolo úspešne pridané.", "success");
      showAddForm.value = false;
      fetchMeals();
    };

    watch(selectedWeek, () => {
      fetchMeals();
    });

    watch(activeTab, (newTab) => {
      showAddForm.value = false;
      showEditor.value = false;
      console.log("Active tab changed:", newTab);
      if (newTab === 1) {
        fetchOrders();
      }
    });

    onMounted(() => {
      fetchMeals();
    });

    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    const showSnackbar = (text, color = 'success') => {
      snackbarText.value = text;
      snackbarColor.value = color;
      snackbar.value = true;
    };

    return {
      meals,
      orders,
      headers,
      isLoading,
      activeTab,
      selectedWeek,
      availableWeeks,
      groupedMeals,
      fetchMeals,
      fetchOrders,
      formatCurrency,
      formatDate,
      formatTime,
      isBeforeOrderTime,
      editOrder,
      showInvoice,
      goBack,
      showAddForm,
      handleMealAdded,
      formatDateToTime,
      handleSupplierUpdated,
      isMealsEmpty,
      isLoadingMeals,
      showOrderItemsEditor,
      showEditor,
      selectedOrderId,
      mobile,
      isBeforeModificationLimit,
      snackbar,
      snackbarText,
      snackbarColor,
    };
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.meal-row {
  height: 80px;
}

.spacer-row {
  height: 1px;
  background-color: #f0f0f0;
}

.v-chip {
  margin-bottom: 4px;
}

.v-card-title.primary {
  color: white;
}

.v-simple-table td,
.v-simple-table th {
  padding: 12px 16px;
}

/* Column-specific styles */
.meal-column {
  width: 20%;
}

.description-column {
  width: 41.5%;
}

.allergens-column {
  width: 20%;
}

.price-column {
  width: 6.25%;
}

/* Add word-wrap for long content */
.v-simple-table td {
  word-wrap: break-word;
  max-width: 0;
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-6 {
  margin-bottom: 24px;
}

.w-100 {
  width: 100%;
}

.text-right {
  text-align: right;
}

.v-btn {
  text-transform: none;
  font-weight: bold;
  color: purple;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 8px;
  line-height: 1.5;
  display: inline-block;
}

.v-btn.disabled-btn {
  color: grey !important;
  opacity: 0.5;
}

.text-h6 {
  font-size: 1.25rem;
  font-weight: 500;
}

.text-center {
  text-align: center;
}
</style>