<template>
    <v-container fluid class="p-0">
      <v-row>
        <v-col cols="12" md="6" lg="5">
          <v-form ref="form" v-model="valid" lazy-validation class="form-container">
            <v-select
              v-model="selectedChildId"
              :items="children"
              item-title="full_name"
              item-value="id"
              label="Vyberte dieťa"
              @update:model-value="onChildChange"
              variant="solo"
              class="input-field"
            ></v-select>
  
            <v-select
              v-if="selectedChild"
              v-model="selectedChild.dining_school"
              :items="schools"
              item-title="name"
              item-value="id"
              label="Preferovaná jedáleň"
              @change="onSchoolChange"
              return-object
              variant="underlined"
              class="input-field"
            >
              <template v-slot:selection="{ item }">
                {{ item.raw.name }}
              </template>
            </v-select>
  
            <v-card-title class="px-0">Automatický výber jedál</v-card-title>
            <v-btn-toggle
              v-model="autoOrderEnabled"
              mandatory
              class="mb-4 input-field"
              @change="onAutoOrderChange"
            >
              <v-btn :value="true" :class="{ 'active-btn': autoOrderEnabled }">
                Povoliť
              </v-btn>
              <v-btn :value="false" :class="{ 'active-btn': !autoOrderEnabled }">
                Zakázať
              </v-btn>
            </v-btn-toggle>
  
            <v-radio-group
              v-if="autoOrderEnabled"
              v-model="autoOrderPreference"
              @change="onAutoOrderChange"
              label="Spôsob výberu jedla"
              class="input-field"
            >
              <v-radio label="Vybrať najlacnejšiu možnosť" value="cheapest"></v-radio>
              <v-radio label="Vybrať náhodné jedlo z menu" value="random"></v-radio>
            </v-radio-group>
  
            <v-radio-group
              v-if="autoOrderEnabled"
              v-model="autoOrderPortionSize"
              @change="onAutoOrderChange"
              label="Veľkosť porcie"
              class="input-field"
            >
              <v-radio label="Celá porcia" value="full"></v-radio>
              <v-radio label="Polovičná porcia" value="half"></v-radio>
            </v-radio-group>
  
            <v-btn
  class="mt-4 submit-btn"
  @click="saveChanges"
  :disabled="!canSaveChanges"
  :loading="isLoading"
  color="purple"
  variant="outlined"
>
  Uložiť zmeny
</v-btn>
          </v-form>
        </v-col>
      </v-row>
  
      <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000" location="top">
        {{ snackbarText }}
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="snackbar = false">
            Zavrieť
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </template>
  
  <script setup>
  import { ref, computed, onMounted } from 'vue';
  import Services from '@/api/services';
  
  const children = ref([]);
  const schools = ref([]);
  const selectedChildId = ref(null);
  const autoOrderEnabled = ref(false);
  const autoOrderPreference = ref('cheapest');
  const autoOrderPortionSize = ref('full');
  const parentId = ref(null);
  const originalSettings = ref({});
  const valid = ref(true);
  
  const snackbar = ref(false);
  const snackbarText = ref('');
  const snackbarColor = ref('success');
  
  onMounted(async () => {
    await fetchChildrenAndSchools();
    await fetchAutoOrderSettings();
  });
  
  const fetchChildrenAndSchools = async () => {
    try {
      parentId.value = await Services.getUserIdByEmail(localStorage.getItem("email"));
      console.log("Getting parent before children", parentId.value);
      const response = await Services.getChildrenAndSchools(parentId.value);
      console.log(response);
      children.value = response.children.map(child => ({
        ...child,
        full_name: `${child.first_name} ${child.last_name}`
      }));
      schools.value = response.schools;
      
      // Save original settings
      originalSettings.value = {
        children: children.value.map(child => ({ ...child })),
        autoOrderEnabled: autoOrderEnabled.value,
        autoOrderPreference: autoOrderPreference.value,
        autoOrderPortionSize: autoOrderPortionSize.value
      };
  
      // Select first child as default
      if (children.value.length > 0) {
        selectedChildId.value = children.value[0].id;
      }
    } catch (error) {
      console.error('Error fetching children and schools:', error);
      showSnackbar('Chyba pri načítaní údajov', 'error');
    }
  };
  
  const fetchAutoOrderSettings = async () => {
  try {
    const settings = await Services.getAutoOrderSettings(selectedChildId.value);
    console.log("Auto order settings", settings);
    autoOrderEnabled.value = settings.enabled;
    autoOrderPreference.value = settings.preference;
    autoOrderPortionSize.value = settings.portion;

    // Update original settings after fetching
    originalSettings.value = {
      ...originalSettings.value,
      autoOrderEnabled: settings.enabled,
      autoOrderPreference: settings.preference,
      autoOrderPortionSize: settings.portion
    };
  } catch (error) {
    console.error('Failed to fetch auto-order settings:', error);
    showSnackbar('Chyba pri načítaní nastavení automatického výberu', 'error');
  }
};
  
  const selectedChild = computed(() => 
    children.value.find(child => child.id === selectedChildId.value)
  );
  
  const hasChanges = computed(() => {
  const childrenChanged = children.value.some(child => {
    const original = originalSettings.value.children.find(c => c.id === child.id);
    return original && original.dining_school.id !== child.dining_school.id;
  });
  const autoOrderChanged = 
    autoOrderEnabled.value !== originalSettings.value.autoOrderEnabled ||
    autoOrderPreference.value !== originalSettings.value.autoOrderPreference ||
    autoOrderPortionSize.value !== originalSettings.value.autoOrderPortionSize;
  
  return childrenChanged || autoOrderChanged;
});
  
const canSaveChanges = computed(() => {
  if (hasChanges.value) {
    if (autoOrderEnabled.value) {
      return autoOrderPreference.value !== '' && autoOrderPortionSize.value !== '';
    }
    return true;
  }
  return false;
});
  
  const onChildChange = async () => {
    console.log('Selected child:', selectedChild.value);
    if (selectedChild.value) {
      await fetchAutoOrderSettings();
    }
  };
  
  const onSchoolChange = () => {
  if (selectedChild.value) {
    console.log('Changed preferred dining school:', selectedChild.value.dining_school.name);
  }
};
  
  const onAutoOrderChange = () => {
    console.log('Auto order settings changed:', { 
      enabled: autoOrderEnabled.value, 
      preference: autoOrderPreference.value,
      portionSize: autoOrderPortionSize.value
    });
  };
  
  const saveChanges = async () => {
  isLoading.value = true;
  try {
    // Update dining schools
    for (const child of children.value) {
      const original = originalSettings.value.children.find(c => c.id === child.id);
      if (original && original.dining_school.id !== child.dining_school.id) {
        await Services.updateChildDiningSchool(child.id, child.dining_school.id);
      }
    }

    // Update auto order settings
    if (autoOrderEnabled.value !== originalSettings.value.autoOrderEnabled ||
        autoOrderPreference.value !== originalSettings.value.autoOrderPreference ||
        autoOrderPortionSize.value !== originalSettings.value.autoOrderPortionSize) {
      await Services.updateAutoOrderSettings(
        selectedChildId.value,
        autoOrderEnabled.value,
        autoOrderPreference.value,
        autoOrderPortionSize.value
      );
    }
    
    // Update original settings
    originalSettings.value = {
      children: children.value.map(child => ({
        ...child,
        dining_school: { ...child.dining_school }
      })),
      autoOrderEnabled: autoOrderEnabled.value,
      autoOrderPreference: autoOrderPreference.value,
      autoOrderPortionSize: autoOrderPortionSize.value
    };

    console.log('All changes saved successfully');
    showSnackbar('Zmeny boli úspešne uložené', 'success');
  } catch (error) {
    console.error('Error saving changes:', error);
    showSnackbar('Chyba pri ukladaní zmien', 'error');
  } finally {
    isLoading.value = false;
  }
};
const isLoading = ref(false);
  const showSnackbar = (text, color = 'success') => {
    snackbarText.value = text;
    snackbarColor.value = color;
    snackbar.value = true;
  };
  </script>
  
  <style scoped>
  .v-btn {
    text-transform: none;
    font-weight: bold;
    color: purple;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  
  .form-container {
    padding: 20px 0;
  }
  
  .input-field {
    margin-bottom: 20px;
  }
  
  .submit-btn {
    text-transform: none;
    font-weight: bold;
    color: purple;
    background-color: transparent !important;
    box-shadow: none !important;
    height: 44px;
    margin-left: 0;
  }
  
  .v-btn-toggle {
    width: 100%;
  }
  
  .v-btn-toggle .v-btn {
    flex-grow: 1;
  }
  
  .v-btn-toggle .v-btn.active-btn {
    background-color: purple !important;
    color: white !important;
  }
  
  .v-radio-group .v-radio {
    margin-bottom: 8px;
  }
  
  @media (max-width: 600px) {
    .form-container {
      padding: 10px 0;
    }
  
    .input-field {
      margin-bottom: 15px;
    }
  }
  </style>
  